import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.onLoad(function(){
    if (document.querySelector('body')) {
        const data = document.querySelector('body').dataset || null;

        if (data.sentryDsn) {
            Sentry.init({
                dsn: data.sentryDsn,
                release: data.release,
                environment: data.environment,
                integrations: [new Integrations.BrowserTracing()],
                tracesSampleRate: 1.0,
            });

            if (data.userId) {
                Sentry.setUser({
                    id: data.userId,
                    email: data.userEmail,
                });
            }
        }
    }
});

